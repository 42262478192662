.btn-hover-effect:hover {
    /* Define the hover effect here */
    filter: brightness(75%);
}
.banner-title{
    font-family: Manrope, sans-serif;
    font-size: 64px;
    font-weight: 400;
    line-height: 72px;
    letter-spacing: -1.2799999713897705px;
    text-align: center;

}
.banner-text{
    font-family: Manrope, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    text-align: center;
    color:rgba(122, 126, 135, 1);
}
.banner-sub-text{
    font-family: Manrope, sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0;
    text-align: center;
    color:rgba(29, 77, 200, 1);

}
.Hero{


}

/* Styles for mobile devices */
@media (min-width: 320px) {
    /* Your mobile-specific styles */
    .hero-img {
        top: -60px;
    }
    .banner-title{
        font-size: 50px;
    }
}

/* Styles for larger mobile devices and small tablets */
@media (min-width: 480px) {
    /* Your styles for larger mobile devices */
    .hero-img {
        top: -90px;
    }
    .banner-title{
        font-size: 50px;
    }

}

/* Styles for tablets in portrait mode and small laptops */
@media (min-width: 768px) {
    /* Your tablet-specific styles */
    .hero-img {
        top: -140px;
    }
}

/* Styles for tablets in landscape mode, small laptops, and desktops */
@media (min-width: 1024px) {
    /* Your styles for tablets in landscape mode and small laptops */
    .hero-img {
        top: -210px;
    }
}

/* Styles for larger desktop monitors and extra-large screens */
@media (min-width: 1200px) {
    /* Your desktop-specific styles */
    .hero-img {
        top: -260px;
    }
}






.whatCanJhoticaDo {
  gap: 60px;
}

.title {
  font-family: Manrope, sans-serif;
  font-size: 42px;
  font-weight: 600;
  line-height: 57px;
  letter-spacing: 0;
  text-align: center;
  color: rgba(29, 30, 34, 1);
}
.power,
.powerText {
  font-family: Manrope, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 3px;
  text-align: left;
  color: rgba(122, 126, 135, 1);
}

.powerText {
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: center;
}

.PowerTitle {
  max-width: 460px !important;
  font-family: Manrope, sans-serif;
  font-size: 32px;
  font-weight: 500;
  line-height: 45px;
  letter-spacing: 0;
  text-align: left;
  color: rgba(29, 30, 34, 1);
  margin-top: 12px;
}
.description {
  max-width: 555px !important;
  font-family: Manrope, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  color: rgba(122, 126, 135, 1);
  margin-top: 20px;
}
.joinUsNowBTN {
  width: auto;
  height: 40px;
  padding: 10px 22px 10px 22px;
  border-radius: 100px;
  gap: 10px;
  font-family: Manrope, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
  color: rgba(255, 255, 255, 1);
}
.BTNText {
  font-family: Manrope, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
  color: rgba(255, 255, 255, 1);
}
.box {
  max-width: 426px;
  max-height: 88px;
  padding: 31px 24px 31px 24px;
  border-radius: 16px;
  gap: 10px;
  border: 1px solid rgba(202, 223, 255, 1);
  background: rgba(249, 251, 255, 1);
  margin-bottom: 12px;
}
.box-text {
  font-family: Manrope, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0;
  color: rgba(95, 99, 109, 1);
  text-align: center;
}
.box-lg {
  max-width: 426px;
  height: auto;
  padding: 24px;
  border-radius: 16px;
  gap: 17px;
  border: 1px solid rgba(202, 223, 255, 1);
  background: rgba(255, 255, 255, 1);
  margin-bottom: 12px;
}
.box-lg-title {
  max-width: 226px !important;
  font-family: Manrope, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0;
  text-align: left;
  color: rgba(29, 30, 34, 1);
}
.box-lg-des {
  max-width: 378px !important;
  font-family: Manrope, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  color: rgba(122, 126, 135, 1);
}
.smartTravel {
  background: rgba(249, 251, 255, 1);
}

.BadgesTitle {
  font-family: Manrope, sans-serif;
  font-size: 32px;
  font-weight: 500;
  line-height: 45px;
  letter-spacing: 0;
  text-align: left;
  color: rgba(29, 30, 34, 1);
}
.BadgesCount {
  width: auto;
  height: 40px;
  padding: 10px 20px 10px 20px;
  border-radius: 100px;
  gap: 10px;
  background: rgba(249, 251, 255, 1);
}
.BadgesCount text {
  font-family: Manrope, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
  color: rgba(22, 22, 22, 1);
}
.Analytics_count {
  font-family: Manrope, sans-serif;
  font-size: 64px;
  font-weight: 300;
  line-height: 87px;
  letter-spacing: 0;
  text-align: center;
  color: rgba(53, 106, 252, 1);
}
.FAQ_Box {
  max-width: 1060px;
  border-radius: 16px;
  gap: 32px;
  border: 1px solid rgba(225, 227, 230, 1);
}
.FAQ_Question_normal {
  font-family: Manrope, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  color: rgba(22, 22, 22, 1);
}
.FAQ_Question_active {
  font-family: Manrope, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  color: rgba(29, 77, 200, 1);
}
.FAQ_Plus_icon {
  width: 16px;
  height: 2px;
  top: 7px;
  border-radius: 10px;
}
.FAQ_Answer {
  font-family: Manrope, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  color: rgba(139, 139, 139, 1);
}
@media (max-width: 576px) {
  .power {
  }
}

.footer-text{
    text-decoration: none;
    font-size: .9rem;
  }
  
  .footer-text:hover{
    text-decoration: underline;
    color: #1d4dc8!important;
  }
  
  .footer-holder{
    background-color: #e8eeff69;
    /* box-shadow: 0px -10px 9px rgba(190, 207, 250, 0.212) !important;  */
    bottom: 0px;
  }
  
  @media (max-width: 485px) {
    .footer-holder {
      flex-direction: column;
    }
    .footer-link-holder{
      padding-top: 1rem;
    }
    .nowrap {
      white-space: nowrap;
  }
  
  .footer-text{
    padding-top: 8px;
  }
  }
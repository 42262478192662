/* Language: css */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

.container {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
}

body {
  font-family: 'Poppins', sans-serif !important;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  overflow-x: hidden;
}

.section {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
}

h1 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 1rem;
}


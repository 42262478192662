body {
  font-family: 'Poppins', sans-serif !important;
}

.Blog-TitleHeading {
  color: var(--Second-Blue, #356afc);
  font-family: Poppins;
  font-size: 1.8rem;
  font-weight: 600;
  text-transform: uppercase;
}

hr {
  opacity: 0.12;
  background: #000;
}

.Blog-SubParagraph {
  color: var(--Anothr-ash, #5e6282);
  font-family: Poppins;
  font-size: 0.9rem;
  font-weight: 300;
}

.Blog-DetailContainer {
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0px 1px 8px 0px rgba(53, 106, 252, 0.17);
}

.Blog-SecondTitleHeading {
  color: var(--Black, #161616);
  font-size: 1.7rem;
  font-weight: 600;
}

.Blog-RegularParagaraph {
  color: var(--Black, #161616);
  font-size: 0.9rem;
  font-weight: 300;
}

.Blog-ThirdTitleHeading {
  padding-top: 20px;
  color: var(--Black, #161616);
  font-size: 1.2rem;
  font-weight: 600;
}


li {
    color: var(--Black, #161616);
    font-size: 0.9rem;
    font-weight: 300;
    margin-bottom: .5rem;
}

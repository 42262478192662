.artwork {
  height: 28rem;
}

.brea-input {
  color: #000000;
  font-size: 16px;
  font-weight: 450;
  width: 100%;
  border-radius: 4px;
  /* padding: .8rem 1rem; */
  padding: 14px 24px;
  margin-bottom: 1.5rem;
  border: 0.25px rgba(0, 0, 0, 0.1) solid;
}
.brea-input::placeholder {
  color: #00000086;
  font-size: 16px;
  font-weight: 300;
}

.brea-button {
  width: 100%;
  background-color: #356afc !important;
  font-size: 1.1 rem !important;
  font-weight: 600 !important;
  padding: 0.6rem 2rem !important;
}

.brea-button:hover {
  background-color: #0f2a6c !important;
}

.sidebar {
  width: fit-content;
}

.subText {
  color: var(--an-ash, #161616);
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.logo-t {
  height: 100%;
}

.social-auth {
  height: 3rem !important;
}

.text-small {
  color: #9e9e9e;
  font-weight: 300;
}

.text-big {
  font-size: 2rem;
  font-weight: 700;
  text-transform: capitalize !important;
  color: #356afc;
  margin-top: -30px;
}

.line-hr {
  border: 0.25px rgba(0, 0, 0, 0.2) solid;
}

@media (max-width: 768px) {
  .loginholder {
    flex-direction: column; /* Stacked in mobile */
  }
  .artwork {
    display: none;
  }

  .logo-2 {
    display: none;
  }
}

@media (min-width: 200px) and (max-width: 480px) {
  .sidebar .brea-input .container {
    width: 100vw;
  }
}

@media (min-width: 576px) and (max-width: 991px) {
  .loginholder .container {
    width: 100vw !important;
  }
}

@media only screen and (min-width: 1081px) {
  .art-holder {
    flex: 0 0 25%;
    margin-right: 4rem;
  }

  .form-holder {
    flex: 0 0 40%;
  }

  .loginholder {
    margin-top: 28px;
    justify-content: between;
  }
}

.nav-link.active {
  font-weight: bold;
  color: #1d4dc8 !important;
}

.main-nav-holder {
   box-shadow: 0px 10px 9px rgba(190, 207, 250, 0.123) !important; 
  padding-left: 6rem;
  padding-right: 6rem;
  width: 100vw!important;
}

@media (max-width: 575px) {
  .main-nav-holder {
    padding: 20px;
  }
}

.username {
  text-transform: uppercase;
  font-size: 1.3rem;
  font-weight: bolder !important;
  margin-top: 1.2rem;
  margin-bottom: 4px;
}

.bio {
  max-width: 55rem;
  font-weight: 100;
  color: #474747b7;
  font-size: 0.85rem;
}

.uavatar {
  width: 4.5rem;
}

.smallheading {
  font-weight: 900;
  text-transform: uppercase;
}

h5 {
  font-family: "Poppins";
  font-weight: bolder !important;
  text-transform: uppercase;
  font-size: 1rem !important;
}

.spot-img {
  height: 4.5rem;
  width: 4.5rem;
  border-radius: 50%;
}

.userDP {
  object-fit: cover;
  height: 100px;
  width: 100px;
  border-radius: 50%;
}

.UserProfileSpotName {
  max-width: 100px;
  margin-top: 0.5rem;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}

.UserProfileSpotName:hover::after {
  content: attr(data-fullname);
  position: absolute;
  top: 100%;
  left: 100%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  white-space: nowrap;
}

.Tooltip {
  position: absolute;
  top: 100%;
  left: 100%;
  transform: translateY(470%);
  background-color: #313131;
  color: #ffffff;
  padding: 5px;
  border-radius: 4px;
  white-space: nowrap;
  font: 0.8em sans-serif;
}

.about-badge {
  transform: rotate(45deg);
  transform-origin: 0 0;
  margin-right: -16.5rem !important;
  margin-top: -8rem !important;
  background: linear-gradient(180deg, #faff00 0%, #faff00 100%);
  justify-content: center;
  align-items: center;
  display: inline-flex;
  height: 3.5rem;
  width: 450px;
  z-index: 0;
}

.badge-text {
  color: #161616;
  font-size: 0.85rem;
  font-family: Poppins;
  font-weight: 600;
  text-transform: uppercase;
  word-wrap: break-word;
  margin-bottom: 0px !important;
}

@media (max-width: 1004px) {
  .bio {
    max-width: 30rem;
  }
}
@media (max-width: 846px) {
  .about-badge {
    display: none;
  }
}

#tooltip-1,
#tooltip-0 {
  display: none;
}

textarea.user-profile,
input.user-profile {
  color: #000000;
  font-weight: 400;
}

.profile-container {
  position: relative;
  margin-right: 3rem !important;
  text-align: center;
}

.outline-button,
.outline-button-deletion {
  max-width: 290px;
  width: 100%;
  background-color: #ffffff !important;
  font-size: 1.1 rem !important;
  color: #ff5a0e;
  padding: 0.6rem 2rem !important;
  border: 1px solid #ff5a0e;
  border-radius: 4px;
}
.outline-button:hover {
  color: #ffffff !important;
  background-color: #ff5a0e !important;
}

.outline-button-deletion {
  border: 1px solid gray;
  color: gray;
}

.outline-button-deletion:hover {
  color: #ffffff !important;
  background-color: gray !important;
}
.file-input {
  opacity: 0;
  font-size: 30px;
}

@media screen and (max-width: 632px) {
  .dpName {
    flex-direction: column;
  }

  .settingInput {
    max-width: calc(80vw - 20px);
  }

  .sticky-button {
    margin: auto;
  }
}

@media screen and (min-width: 1453px) {
  .profile-container {
    padding: 0rem 16rem;
  }
}

.holder {
  background: rgb(255, 255, 255);
  border-radius: 10px;
  border: 0.25px #9ba5b775 solid;
  height: 85vh;
  overflow: auto;
}

.suggestion {
  flex: 0 0 22%;
}

.chat {
  flex: 0 0 78%;
  position: relative;

}

.dot {
  height: 10px;
  width: 10px;
  background-color: #3abf38;
  border-radius: 50%;
  display: inline-block;
}

.online {
  margin-top: -20px;
}

.jhot-avatar-chat {
  height: 50px;
}

.jhot-text {
  padding: 10px 18px;
  background-color: #eeeeee;
  border-radius: 20px 20px 20px 0px;
  margin-bottom: 0;
  max-width: 500px;
}

.user-text {
  padding: 10px 18px;
  color: white;
  background-color: #356afc;
  border-radius: 20px 0px 20px 20px;
  margin-bottom: 0;
  max-width: 500px;
  margin-left: auto;
}



.typing-input{
  position: absolute; 
  bottom: 0;
  left: 0;
  width: 95%;

  z-index: 999;
  
}

 /* .typing-input {
  position: relative;
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 999;
} */

.input-style {
  border-radius: 50px;
  outline: none;
  border: 0.5px solid #9ba5b771;
  width: 100%;
}

.input-style::placeholder {
  color: #999;
}

#send-button {
  outline: none;
  background-color: white;
  border: none;
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
}

.qtojhot {
  padding: 10px 20px;
  background-color: #ecf1ff;
  border-radius: 10px;
  max-width: 100%;
  margin-top: 1rem;
  border: rgba(153, 153, 153, 0.322) 1px solid;
  text-align: left;
  font-size: 14.5px;
}



@media (max-width: 998px) {
 
  .suggestion {
    display: none;
  }

 .holder, .container {
    margin: 0;
    padding: 0;
    border: none;
  }
  .chat {
    flex: 0 0 98%;
    margin: 0px!important;
    padding: 0;
  }

  .jhot-avatar-chat, .typing-input{
    margin-left: 8px!important;
  }
  .user-text, .typing-input{
    margin-right: 8px!important;
  }
  .jhot-holder{
    padding: 16px 8px!important;
  }
}

@media (min-width: 999px) {
 
   .chat {
    max-width: 72vw;
   }

 }
 

.app-landing-page {
  position: relative;
  /* height: 90vh; */
  overflow-y: auto;
  justify-content: center;
  background-image: url('../assets/images/bg.jpg');
  background-repeat: no-repeat;
  background-position: 100% 0;
  background-size: 40%;
}

.app-landing-page::-webkit-scrollbar {
  display: none;
}

.app-landing-page {
  scrollbar-width: none;
}

.mockup1 {
  max-height: 70vh;
}

.mockup-holder {
  color: aliceblue;
  position: sticky;
  top: 0;
  right: 0;
  z-index: 1;
  max-height: 100vh;
}

.chat-holder {
  max-width: 70vw;
  margin-left: 0rem;
}

.stores {
  border: 0 none;
  background-color: rgba(240, 255, 255, 0);
}

.appstores {
  height: 3rem;
}

.heading {
  font-size: 3.5rem;
  letter-spacing: -1px;
  line-height: 1.7rem;
  font-weight: 800;
  text-transform: uppercase;
}

.outline {
  color: rgb(255, 255, 255);
  text-shadow: -1px -1px 0 #1d4dc8, 1px -1px 0 #1d4dc8, -1px 1px 0 #1d4dc8,
    1px 1px 0 #1d4dc8;
}
.fill {
  color: #1d4dc8;
}

.questionSet {
  width: 47vw;
  padding: 0.8rem;
  border-radius: 10px;
  margin-bottom: 10px;
  background-color: #1d4dc811;
  font-size: 14px;
}
.ans {
  width: 47vw;
  padding: 0.8rem;
  border-radius: 10px;
  margin-top: 5px;
  margin-bottom: 10px;
  background-color: #1d4dc8;
  color: aliceblue;
  font-size: 14px;
  display: none;
}
.ques {
  margin-bottom: 0px;
}
.ask {
  margin-bottom: 0px;
  padding-bottom: 0px;
  font-size: 14px;
  text-decoration: underline;
  background-color: #1d4dc800;
  border: 0;
}








/* media query respon */
@media (max-width: 1528px) {
  .questionSet,
  .ans {
    width: 45vw;
  }
}
@media (max-width: 1272px) {
  .questionSet,
  .ans {
    width: 25rem;
  }
}
@media (max-width: 1118px) {
  .questionSet,
  .ans {
    width: 23rem;
  }

  .mockup1 {
    width: 28rem;
  }
}

@media (max-width: 1014px) {
  .mockup1 {
    width: 24rem;
  }
}
@media (max-width: 974px) {
  .questionSet,
  .ans {
    width: 20rem;
  }

  .mockup1 {
    width: 24rem;
  }
}
@media (max-width: 918px) {
  .questionSet,
  .ans {
    width: 20rem;
  }

  .mockup1 {
    width: 20rem;
  }
  .appstores {
    height: 2.5rem;
  }
}

@media (max-width: 918px) {
  .questionSet,
  .ans {
    width: 20rem;
  }

  .mockup1 {
    width: 20rem;
  }
  .appstores {
    height: 2.5rem;
  }
  .chat-holder {
    margin: 0;
  }
}

@media (max-width: 852px) {
  .questionSet,
  .ans {
    width: 20rem;
  }

  .mockup1 {
    width: 20rem;
  }
  .appstores {
    height: 2.5rem;
  }
  .chat-holder {
    margin: 0;
  }
}

@media (max-width: 767px) {
  .app-landing-page {
    flex-direction: column;
    background-image: none;
    align-items: center;
    justify-content: center;
    height: fit-content;
    margin: 0;
  }

  .chat-holder {
    margin: 0px;
    max-width: 70vw;
  }

  .questionSet,
  .ans {
    width: 24rem;
  }

  .appstores {
    height: 3rem;
  }

  .conv {
    margin-right: 100px !important;
  }

  .mockup1 {
    display: none;
  }

  .appstores {
    height: 4rem;
  }
}

@media (max-width: 582px) {
  .store-holder {
    padding-left: 30px;
  }
}

@media (max-width: 474px) {
  .chat-holder {
    margin: 0px;
    max-width: 70vw;
  }

  .questionSet,
  .ans {
    width: 20rem;
  }

  .appstores {
    height: 2.5rem;
  }
}
@media (max-width: 392px) {


  .questionSet,
  .ans {
    width: 18rem;
  }

  .appstores {
    height: 3.5rem;
    margin-top: 20px;
  }

  .store-holder {
    display: flex;
    flex-direction: column;
  }
}
